import React, { useCallback } from 'react';
import { useRazorpay } from "react-razorpay";


function RazorpayPayment({ payableAmount }) {
    console.log('payableAmount-----', payableAmount);
    const Razorpay = useRazorpay();


    const handlePayment = useCallback(async (data) => {
        console.log('paymetRzor', data);
        // const filterSecrateKy = paymentSelectData?.map((item) => {
        //   return item
        // })
        // console.log(filterSecrateKy);


        // const filterRoazarKey = filterSecrateKy?.filter((item) => {
        //   return item?.slug == 'razorpay'
        // })
        // console.log('filterRoazarKey', filterRoazarKey[0]?.key);
        try {
            // const res = await rozarPayApi(rozarPayData);
            // "rzp_test_9VeeiBAgDSX9Tq"
            // console.log('hkjbhjkhjlknl', user);

            const options = {
                key: "rzp_test_Y1vZ5apL1BJ4Gg", // Replace with your Razorpay key
                // amount: 50000, // Amount in paise (500 INR)
                amount: payableAmount * 100,
                currency: "INR",
                name: "Abaris Softech",
                description: "Test Transaction",
                image: "https://your-logo-url.com", // Optional logo URL
                handler: (response) => {
                    console.log(response);
                    alert(`Payment ID: ${response.razorpay_payment_id}`);
                },
                prefill: {
                    name: "John Doe",
                    email: "john.doe@example.com",
                    contact: "9999999999",
                },
                theme: {
                    color: "#3399cc",
                },
            };

            // const rzpay = new Razorpay(options);
            const rzpay = new window.Razorpay(options);

            rzpay.on("payment.failed", function (response) {
                // console.log(response);

                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
            });
            rzpay.open();
        } catch (error) {
            console.error("Error in handlePayment:", error);
        }
    }, [Razorpay]);




    return (
        <>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="MTF"
                    id="flexRadioDefault59"
                    onClick={handlePayment}
                />
                <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault59"
                >
                    Pay with RazorPay
                </label>
            </div>
        </>
    )
}

export default RazorpayPayment