import React from "react";
import Careers from "../../components/careers/Careers";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function CareersPage() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Career | WorldWide Exports</title>
        <meta name="keyword" content="WorldWide Exports" />
        <meta name="description" content="WorldWide Exports" />
      </Helmet>
      <Breadcrumb title="Careers" t={t} />
      <Careers />
    </>
  );
}

export default CareersPage;
