import React from "react";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6">
              <div className="image">
                <img src={a1} alt="" /> 
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="container">
                <h3 className="subtitle">{t("About Us - WorldWide Exports")}</h3>
                {/* <p className="">{data?.about_company}</p> */}
                {/* <p>{t("about-1")}</p> */}
                <p>
                  Established in the year 2000, Worldwide Exports is a renowned
                  organization known for manufacturing, wholesaling and
                  exporting Handicrafts and Home Furnishing products. Our range
                  of products comprises Bed Cover, Curtain, Cushion Covers,
                  Dollies, Fashion Items, Pouch, Runner, Table Cover and Tissue
                  Box Cover. Manufactured using high quality fabric, these are
                  available in various styles, patterns and designs.
                </p>
                <p>
                  Our organization has become synonymous with modernity and
                  traditionalism by offering the latest and the trendiest home
                  furnishing, beaded items, handicrafts and others.
                  State-of-the-art infrastructure, international quality norms,
                  timely deliveries and competitive prices are instrumental in
                  the creation of splendid assortment of Handicrafts and Home
                  Furnishing products. Top-notch quality and unmatched designs
                  of our products have created a huge demand worldwide.
                </p>
                <p>
                  We have been able to sustain the trust of our clients as we
                  keep a track record of 100% exports across the world,
                  especially in the Middle East and Europe. Further, our
                  membership of the prestigious Synthetic and Rayon Textiles
                  Export Promotion Council (SRTEPC) has helped us in designing
                  our range at par with industry standards.
                </p>
                <p>
                  Under the able guidance of our mentor Mr. Rafi Ahmed, we have
                  carved a niche for ourselves in the international market. His
                  in-depth knowledge of the industry has helped us in achieving
                  due recognition amongst our clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
